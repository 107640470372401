import React, { useCallback, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  User,
  Pagination,
  Selection,
  ChipProps,
  SortDescriptor,
} from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Spinner,
  Switch,
} from "@nextui-org/react";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import {
  ArrowDown,
  PencilSimpleLine,
  Trash,
  WarningCircle,
  XCircle,
} from "@phosphor-icons/react";
import { columns, statusOptions } from "./data";
import { SwitchComponent } from "./switch";
import { capitalize } from "./utils";
import { useStateManager } from "../handlers/statemanager";
import {
  deleteOrgUser,
  fetchOrganizationUsers,
  updateLicense,
  fetchInviteUser
} from "../handlers/api";
const statusColorMap: Record<string, ChipProps["color"]> = {
  active: "success",
  paused: "danger",
  vacation: "warning",
};

const INITIAL_VISIBLE_COLUMNS = [
  "name",
  "team",
  "role",
  "status",
  "actions",
  "email",
];

export default function App() {
  const { state } = useStateManager();
  const concatArray = state.users.concat(state.invitedUsers);
  
  const [users, setUsers] = React.useState(concatArray);
  const [filterValue, setFilterValue] = React.useState("");
  const [isSwitchSelected, setIsSwitchSelected] = React.useState(true);
  const [selectedKeys, setSelectedKeys] = React.useState<Selection>(
    new Set([])
  );
  const [visibleColumns, setVisibleColumns] = React.useState<Selection>(
    new Set(INITIAL_VISIBLE_COLUMNS)
  );
  const [statusFilter] = React.useState<Selection>("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isToggleLoading, setIsToggleLoading] = React.useState(false);
  let isLicenseUpdateLoading = false;
  const { onOpenChange } = useDisclosure();

  const handleUpdateLicense = async (email: string) => {
    // Find the user whose license needs to be updated
    isLicenseUpdateLoading = true;
    const findUser = state?.licenses?.licenses?.find(
      (license: any) => license.email === email
    );

    if (!findUser) {
      console.error("User not found");
      return;
    }

    // Determine the new status
    const updatedStatus = findUser.status === "active" ? "cancelled" : "active";

    // Update the state with the new status
    const updatedLicenses = state.licenses.licenses.map((license: any) =>
      license.email === email ? { ...license, status: updatedStatus } : license
    );

    // Make the API call to update the license
    try {
      const data = await updateLicense(findUser.id, updatedStatus);
      isLicenseUpdateLoading = false;
      state.setUsers(null)
      const call = await fetchOrganizationUsers(
        state.organizationId
      );
      state.setUsers(call);

      
      
    } catch (error) {
      console.error("Failed to update license:", error);
    }

    // Optionally refresh organization users or perform other state updates
    // state.refreshOrganizationUsers();
  };


  const deleteSuccessNotify = () =>
    toast(
      <div className="flex w-80 items-start rounded-md bg-[#EDF7ED]  gap-2 border-red-500">
        <div className="flex h-full items-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M15.2074 6.94825L9.16658 12.9891L5.87575 9.70742L4.58325 10.9999L9.16658 15.5833L16.4999 8.24992L15.2074 6.94825ZM10.9999 1.83325C5.93992 1.83325 1.83325 5.93992 1.83325 10.9999C1.83325 16.0599 5.93992 20.1666 10.9999 20.1666C16.0599 20.1666 20.1666 16.0599 20.1666 10.9999C20.1666 5.93992 16.0599 1.83325 10.9999 1.83325ZM10.9999 18.3333C6.94825 18.3333 3.66659 15.0516 3.66659 10.9999C3.66659 6.94825 6.94825 3.66659 10.9999 3.66659C15.0516 3.66659 18.3333 6.94825 18.3333 10.9999C18.3333 15.0516 15.0516 18.3333 10.9999 18.3333Z"
              fill="#2E7D32"
            />
          </svg>
        </div>
        <div className="flex flex-col ">
          <p className="text-green-900 font-inter text-base">
            Successfully deleted
          </p>
          <p className="text-green-900 font-roboto font-normal text-body2 text-sm leading-snug tracking-tighter">
            User profile has been archived
          </p>
        </div>
      </div>
    );

  const deleteErrorNotify = () =>
    toast(
      <div className="flex w-80 items-start rounded-md bg-[#F31260] gap-2">
        <div className="flex h-full items-start">
          <WarningCircle size={22} className="text-white" />
        </div>
        <div className="flex flex-col ">
          <p className="text-white font-inter text-base">Update Error</p>
          <p className="text-white font-roboto font-normal text-body2 text-sm leading-snug tracking-tighter">
            There was an error submitting your request
          </p>
        </div>
      </div>
    );
  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: "age",
    direction: "ascending",
  });

  const handleDeleteModel = useCallback(() => {
    state.setDeleteModelOpen(!state.deleteModelOpen);
  }, [state]);

  React.useEffect(() => {
    
    if (state.users.length > 0) {
      setIsLoading(false);
    }
  }, [state.users]);
  type User = (typeof users)[0];

  const [page, setPage] = React.useState(1);

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return columns;

    return columns.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...users];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((user) =>
        user.user_first_name.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== statusOptions.length
    ) {
      filteredUsers = filteredUsers.filter((user) =>
        Array.from(statusFilter).includes(user.status)
      );
    }

    return filteredUsers;
  }, [users, filterValue, statusFilter, hasSearchFilter]);

  React.useEffect(() => {
    
  }, [state.users]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a?: User, b?: User) => {
      const first = a?.[sortDescriptor?.column as keyof User] as number;
      const second = b?.[sortDescriptor?.column as keyof User] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor?.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const renderCell = React.useCallback(
    (user: User, columnKey: React.Key) => {
      const cellValue = user[columnKey as keyof User];

      

      let toggleLoading = false;

      switch (columnKey) {
        case "name":
          return <>{cellValue ? cellValue : "Awaiting user login"}</>;        
        case "role":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-small capitalize">
                {cellValue === 1 ? "Admin" : "Employee"}
              </p>
            </div>
          );
        case "status":
          return (
            <Chip
              className="capitalize"
              color={statusColorMap[user.status]}
              size="sm"
              variant="flat"
            >
              {user.active}
            </Chip>
          );
        case "team":
          return (
            <div className="flex flex-col">
              {cellValue === null ? (
                "Not Assigned"
              ) : (
                <>
                  {state.organizationTeams
                    .filter((team: any) => team.id === user.team)
                    .map((team: any) => (
                      <p
                        key={team.id}
                        className="text-bold text-tiny capitalize text-default-400"
                      >
                        {team.name}
                      </p>
                    ))}
                </>
              )}
            </div>
          );
        case "actions":
          // const [selected, setSelected] = useState(false)
          // React.useEffect(() => {
          //   if(user.status === "active") setSelected(true)
          //     else
          // }, [user])

          return (
            <>
              <div className=" flex items-center">
                {/* {user.email ? null : ( */}

                {/* )} */}
                {/* {user.email ? null : ( */}
                {/* <Trash
                    onClick={() => {
                      handleDeleteModel();
                      state.setEditUserDetails(user);
                    }}
                    className="cursor-pointer hover:scale-110 transition-transform duration-300"
                    color="red"
                    size={18}
                  /> */}
                {/* )} */}
                  <SwitchComponent user={user}  isToggleLoading={isToggleLoading} setIsToggleLoading={setIsToggleLoading} />
                {state.userDetails.organizations[0].role !== 1 ? null : (
                  <PencilSimpleLine
                    color="#71717A"
                    onClick={() => {
                      state.setEditUserModelOpen(true);
                      
                      

                      state.setEditUserDetails(user);
                    }}
                    className="cursor-pointer hover:scale-110 transition-transform duration-300"
                    size={18}
                  />
                )}
              </div>
            </>
          );
        default:
          return cellValue;
      }
    },
    [handleDeleteModel, state]
  );

  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onRowsPerPageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
    },
    []
  );

  const onSearchChange = React.useCallback((value?: string) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  React.useEffect(() => {
    let a = state.users.concat(state.invitedUsers);
    setUsers(a);
  }, [state.users, state.invitedUsers]);

  async function handleDeleteOrgUser() {
    setIsLoading(true);
    const data = await deleteOrgUser(
      state.organizationId,
      state.editUserDetails.id
    );
    
    
    if (data === undefined) {
      deleteErrorNotify();
      return;
    }
    // 
    if (data) {
      // onClose()
      state.setDeleteModelOpen(!state.deleteModelOpen);
      const call = await fetchOrganizationUsers(state.organizationId);
      const activeUsers = call.filter((user: any) => user.active === true);
      

      const archivedUsers = call.filter((user: any) => user.active === false);
      

      setUsers(activeUsers);
      state.setArchivedUsers(archivedUsers);
      state.setUsers(activeUsers);
    }
    setIsLoading(false);
    deleteSuccessNotify();
  }

  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end ">
          <Input
            isClearable
            className="w-full sm:max-w-[100%]"
            placeholder="Search"
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          />
        </div>
        <div className="flex justify-between items-center">
          <span className="text-default-400 text-small">
            Total {users.length} users
          </span>
          <label className="flex items-center text-default-400 text-small">
            {/* Rows per page:
            <select
              className="bg-transparent outline-none text-default-400 text-small"
              onChange={onRowsPerPageChange}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
            </select> */}
          </label>
        </div>
      </div>
    );
  }, [
    filterValue,
    visibleColumns,
    onSearchChange,
    onRowsPerPageChange,
    users.length,
    onClear,
  ]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <span className="w-[30%] text-small text-default-400">

        </span>
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={pages}
          onChange={setPage}
        />
        <div className="hidden sm:flex w-[30%] justify-end gap-2">
          <Button
            isDisabled={pages === 1}
            size="sm"
            variant="flat"
            onPress={onPreviousPage}
          >
            Previous
          </Button>
          <Button
            isDisabled={pages === 1}
            size="sm"
            variant="flat"
            onPress={onNextPage}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }, [
    selectedKeys,
    page,
    pages,
    filteredItems.length,
    onNextPage,
    onPreviousPage,
  ]);

  return (
    <div>
      <Modal
        isOpen={state.deleteModelOpen}
        onOpenChange={onOpenChange}
        onClose={handleDeleteModel}
        placement="top-center"
        size="md"
        className="min-h-[30%]"
        backdrop="blur"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex p-4 justify-between items-center bg-[#FEE7EF] text-base leading-6 font-bold font-inter text-black self-stretch">
                Archive User
              </ModalHeader>
              {state.editUserDetails !== null ? (
                <>
                  {" "}
                  <ModalBody className="pt-8 px-4 pb-8">
                    <div className="flex flex-col justify-center items-start gap-8 self-stretch">
                      <p className="text-black font-inter text-base font-bold leading-6 self-stretch">
                        Are you sure you want to delete this user?
                      </p>
                      <p className="text-black font-inter text-sm font-normal leading-5 self-stretch">
                        Deleting a user will archive them and any role
                        associated with the User will be removed.
                      </p>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg border-2 border-gray-300 text-base leading-6  font-inter text-black"
                      variant="bordered"
                      onPress={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="danger"
                      className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg text-base leading-6  font-inter "
                      onClick={handleDeleteOrgUser}
                      isLoading={isLoading}
                    >
                      Delete
                    </Button>
                  </ModalFooter>
                </>
              ) : (
                <Spinner size="lg" />
              )}
            </>
          )}
        </ModalContent>
        <Toaster position="top-right">
          {(t) => (
            <ToastBar toast={t} style={{ background: "#F31260" }}>
              {({ icon, message }) => (
                <div>
                  {icon}
                  {message}
                  {t.type !== "loading" && (
                    <button
                      onClick={() => toast.dismiss(t.id)}
                      className="absolute top-0 right-0 p-4"
                    >
                      <XCircle className="text-white" />
                    </button>
                  )}
                </div>
              )}
            </ToastBar>
          )}
        </Toaster>
        <Toaster position="top-right">
          {(t) => (
            <ToastBar toast={t} style={{ background: "#EDF7ED" }}>
              {({ icon, message }) => (
                <div>
                  {icon}
                  {message}
                  {t.type !== "loading" && (
                    <button
                      onClick={() => toast.dismiss(t.id)}
                      className="absolute top-0 right-0 p-4"
                    >
                      <XCircle />
                    </button>
                  )}
                </div>
              )}
            </ToastBar>
          )}
        </Toaster>
      </Modal>
      <Table
        aria-label="Example table with custom cells, pagination and sorting"
        isHeaderSticky
        showSelectionCheckboxes={false}
        bottomContent={bottomContent}
        bottomContentPlacement="outside"
        classNames={{
          wrapper: "max-h-[340px]",
        }}
        selectedKeys={selectedKeys}
        selectionMode="none"
        sortDescriptor={sortDescriptor}
        topContent={topContent}
        topContentPlacement="outside"
        onSelectionChange={setSelectedKeys}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={headerColumns}>
          {(column) => (
            <TableColumn
              key={column.uid}
              align={column.uid === "actions" ? "center" : "start"}
              allowsSorting={column.sortable}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody emptyContent={"No users found"} items={sortedItems}>
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
