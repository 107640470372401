import React, { createContext, useContext, useState, useCallback } from "react";
import {
  fetchUserId,
  fetchUserDetails,
  fetchOrganization,
  fetchOrganizationUsers,
  fetchOrganizationTeams,
  fetchInviteList,
  fetchLicenses,
} from "./api";
import Cookies from "js-cookie";
import { handleAuthRedirect } from "../page_components/auth/microsoft-auth";

interface StateManagerContextType {
  state: {
    userId: null | string | void;
    organizationId: any;
    users: any;
    setUsers: any;
    editUserModelOpen: any;
    setEditUserModelOpen: any;
    editUserDetails: any;
    setEditUserDetails: any;
    editUserEmail: any;
    setEditUserEmail: any;
    organization: any;
    setOrganization: any;
    modelOpen: any;
    setModelOpen: any;
    userDetails: any;
    deleteModelOpen: any;
    setDeleteModelOpen: any;
    isSameClickedUser: any;
    setIsSameClickedUser: any;
    organizationTeams: any;
    archivedUsers: any;
    setArchivedUsers: any;
    invitedUsers: any;
    setInvitedUsers: any;
    tenantId: string | null;
    licenses: any;
    refreshOrganizationUsers: any;
    setLicenses: any;
    isSwitchLoading: any;
    setIsSwitchLoading: any;
  };
  refreshOrganizationUsers: () => void;
}

// Create the context
const StateManagerContext = createContext<StateManagerContextType | undefined>(
  undefined
);

// Define your provider component
export const StateManagerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Define your state and any state manipulation functions here
  const [userId, setUserId] = useState<null | string | void>(null);
  const [userDetails, setUserDetails] = useState<null | string | void>(null);
  const [organizationId, setOrganizationId] = useState<any>(null);
  const [organization, setOrganization] = useState(null);
  const [organizationTeams, setOrganizationTeams] = useState(null);
  const [users, setUsers] = useState<any>(null);
  const [editUserModelOpen, setEditUserModelOpen] = useState(null);
  const [editUserDetails, setEditUserDetails] = useState(null);
  const [editUserEmail, setEditUserEmail] = useState(null);
  const [modelOpen, setModelOpen] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [isSameClickedUser, setIsSameClickedUser] = useState(false);
  const [archivedUsers, setArchivedUsers] = useState(null);
  const [invitedUsers, setInvitedUsers] = useState(null);
  const [tenantId, setTenantId] = useState<string | null>(null);
  const [licenses, setLicenses] = useState<any>(null);

  const [isSwitchLoading, setIsSwitchLoading] = useState(false);

  const refreshOrganizationUsers = useCallback(
    async (newUserEmail?: string) => {
      if (!organizationId) return;

      setUsers(null);
      setInvitedUsers(null);

      const organizationUsers = await fetchOrganizationUsers(organizationId);

      const activeUsers = organizationUsers.filter(
        (user: any) => user.active === true
      );

      const archivedUsers = organizationUsers.filter(
        (user: any) => user.active === false
      );

      const invitedUsers = await fetchInviteList(organizationId);

      if (newUserEmail) {
        const newUserIndex = invitedUsers.findIndex(
          (user: any) => user.email === newUserEmail
        );
        if (newUserIndex !== -1) {
          const [newUser] = invitedUsers.splice(newUserIndex, 1);

          setUsers([newUser, ...activeUsers]);
        } else {
          setUsers(activeUsers);
        }
      } else {
        setUsers(activeUsers);
      }

      setArchivedUsers(archivedUsers);
      setInvitedUsers(invitedUsers);
    },
    [organizationId]
  );

  const [initialLoad, setInitialLoad] = useState(true);

  React.useEffect(() => {
    const call = async () => {
      try {
        const userId = await fetchUserId();

        if (userId) {
          await fetchData(userId);
        } else {
          try {
            const data = await handleAuthRedirect();
            if (!data) return window.location.assign("/admin_panel/login");
            const userIdAfterRedirect = await fetchUserId();
            if (userIdAfterRedirect) {
              await fetchData(userIdAfterRedirect);
            } else {
              window.location.assign("/admin_panel/login");
            }
          } catch (err) {
            window.location.assign("/admin_panel/login");
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setInitialLoad(false);
      }
    };

    const fetchData = async (userId: any) => {
      const userDetails = await fetchUserDetails(userId.user_id);
      setUserId(userId.user_id);
      setUserDetails(userDetails);
      setOrganizationId(userDetails.organizations[0].organization);
      const organization = await fetchOrganization(
        userDetails.organizations[0].organization
      );
      setOrganization(organization);
      setTenantId(organization.tenant_id);
      const organizationTeams = await fetchOrganizationTeams(
        userDetails.organizations[0].organization
      );
      setOrganizationTeams(organizationTeams);
      const licenses = await fetchLicenses(organization.tenant_id);
      setLicenses(licenses);
      await refreshOrganizationUsers();
    };

    if (initialLoad) {
      call();
    }
  }, [refreshOrganizationUsers, initialLoad]);

  const state = {
    userId,
    organizationId,
    users,
    editUserModelOpen,
    setEditUserModelOpen,
    editUserDetails,
    setEditUserDetails,
    editUserEmail,
    setEditUserEmail,
    organization,
    setOrganization,
    modelOpen,
    setModelOpen,
    userDetails,
    deleteModelOpen,
    setDeleteModelOpen,
    isSameClickedUser,
    setIsSameClickedUser,
    organizationTeams,
    setUsers,
    archivedUsers,
    setArchivedUsers,
    invitedUsers,
    setInvitedUsers,
    tenantId,
    licenses,
    refreshOrganizationUsers,
    setLicenses,
    isSwitchLoading,
    setIsSwitchLoading,
  };

  return (
    <StateManagerContext.Provider value={{ state, refreshOrganizationUsers }}>
      {children}
    </StateManagerContext.Provider>
  );
};

// Custom hook to consume the StateManager context
export const useStateManager = () => {
  const context = useContext(StateManagerContext);
  if (!context) {
    throw new Error(
      "useStateManager must be used within a StateManagerProvider"
    );
  }
  return context;
};
