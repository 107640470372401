import Cookies from "js-cookie";

export const logoutUser = async () => {
  try {
    const csrfToken = Cookies.get("csrftoken") || '';
    const headers: HeadersInit = {
      'Content-Type': 'application/json'
    };
    if (csrfToken) {
      headers['X-CSRFToken'] = csrfToken;
    }
    const response = await fetch(`/accounts/logout/`, {
      method: "POST",
      headers: headers,
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    console.error("Logout Error:", error);
  }
};