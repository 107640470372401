import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Organization from './organization';
import Archived from './archived';
import Productivity from './productivity';
interface PageRouterProps {
  // Define any props you need for your PageRouter component
}

const PageRouter: React.FC<PageRouterProps> = () => {
  return (
    <div className='flex grow bg-[#FAFAFA] p-2  lg:p-8 overflow-scroll '>
      <Routes>
        <Route path="/" element={<Navigate to="/organization" replace />} />
        <Route path="/organization" element={<Organization />} />
        <Route path="/archived" element={<Archived />} />
        <Route path="/productivity" element={<Productivity />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};



const NotFound: React.FC = () => {
  return <h1>404 - Not Found</h1>;
};

export default PageRouter;