import React, { useEffect, useState, useMemo } from "react";
import { Switch } from "@nextui-org/react";
import { useStateManager } from "../handlers/statemanager";
import { toggleLicenseSuccess, toggleLicenseError } from "./toasts/toasts";
import { createLicenses, updateLicense, fetchOrganizationUsers, fetchLicenses } from "../handlers/api";

export const SwitchComponent = (props: any) => {
  const { user } = props;
  const { state } = useStateManager();

  const [isToggled, setIsToggled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkLicenseEmailMatch(user.email);
  }, [state?.licenses, state?.users]);

  function checkLicenseEmailMatch(email: string) {
    if (!state?.licenses) return false;
    const result = state?.licenses?.licenses?.some(
      (license: any) => license.email === email && license?.status === "active"
    );
    setIsToggled(result);
    return result;
  }

  const isDisabled = useMemo(() => {
    return (
      state.licenses.available_licenses <= 0 &&
      !checkLicenseEmailMatch(user.email) ||
      loading ||
      state.isSwitchLoading
    );
  }, [state.licenses.available_licenses, user.email, loading, state.isSwitchLoading]);

  const handleCreateLicense = async () => {
    if (state.licenses.available_licenses > 0) {
      try {
        const data = await createLicenses(
          user.email,
          "paid_enterprise_microsoft",
          "active",
          state.tenantId
        );
        const callOne = await fetchLicenses(state.tenantId);
        const callTwo = await fetchOrganizationUsers(state.organizationId);
        state.setLicenses(callOne);
        state.setUsers(callTwo);
        toggleLicenseSuccess();
      } catch (err) {
        console.error(err);
        toggleLicenseError();
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUpdateLicense = async () => {
    state.setIsSwitchLoading(true) 
    setLoading(true);
    const findUser = state?.licenses?.licenses?.find(
      (license: any) => license.email === user.email
    );

    if (!findUser) {
      await handleCreateLicense();
      setIsToggled(!isToggled);
      state.setIsSwitchLoading(false) 
      return;
    }

    const updatedStatus = findUser.status === "active" ? "cancelled" : "active";

    try {
      await updateLicense(findUser.id, updatedStatus);
      const callOne = await fetchLicenses(state.tenantId);
      const callTwo = await fetchOrganizationUsers(state.organizationId);
      state.setLicenses(callOne);
      state.setUsers(callTwo);
      toggleLicenseSuccess();
    } catch (error) {
      setIsToggled(!isToggled);
      console.error("Failed to update license:", error);
      toggleLicenseError();
    } finally {
      setLoading(false);
      checkLicenseEmailMatch(user.email);
      state.setIsSwitchLoading(false) 
    }
  };

  return (
    <>
      <Switch
        isSelected={isToggled}
        onChange={handleUpdateLicense}
        isDisabled={isDisabled}
      />
    </>
  );
};
