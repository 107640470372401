import React from "react";
import { Button, Chip } from "@nextui-org/react";
import { SealCheck, Clock } from "@phosphor-icons/react";

import { redirectToMicrosoftAuth } from "./microsoft-auth";

export const LoginHome = () => {

  return (
    <div className="bg-[#1F126F] flex min-h-screen w-full items-center justify-center md:py-0 md:px-0 py-12 px-12">
      <div className="container flex justify-center">
        <div className="flex max-w-[950px] min-h-[500px] grow md:pr-6 pr-0 gap-10 rounded-3xl bg-[#F9FAFB] flex-col md:flex-row overflow-hidden">
          <div className="flex flex-col w-full md:w-[390px] px-6 py-6 md:px-10 md:py-10 justify-center items-center gap-8 rounded-t-3xl md:rounded-l-3xl md:rounded-tr-none bg-white border-b-2 md:border-b-0 md:border-r-2 border-gray-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="390"
              height="330"
              viewBox="0 0 390 330"
              fill="none"
            >
              <rect
                x="25.9209"
                y="72.4805"
                width="337.174"
                height="198.326"
                rx="9.62963"
                fill="#3E3691"
              />
              <path
                d="M292.867 133.968C248.478 133.968 212.391 170.362 212.391 215.146C212.391 259.93 248.52 296.325 292.867 296.325C337.214 296.325 373.342 259.93 373.342 215.146C373.342 170.362 337.214 133.968 292.867 133.968ZM336.338 215.105C336.338 239.27 316.855 258.887 292.909 258.887C268.962 258.887 249.479 239.229 249.479 215.105C249.479 190.981 268.962 171.322 292.909 171.322C316.855 171.322 336.338 190.981 336.338 215.105Z"
                fill="#7973B6"
              />
              <path
                d="M118.349 235.83L26.8361 182.995L8.2384 215.208L128.911 284.878L172.915 208.662L143.754 191.826L118.349 235.83Z"
                fill="#F58767"
              />
              <path
                d="M258.822 53.5395L224.274 76.1405L226.686 34.9854L192.439 15.2131L190.056 100.691L169.653 136.03L199.53 153.279L219.78 118.204L292.918 73.2248L258.822 53.5395Z"
                fill="#ED5C5E"
              />
              <path
                d="M105.6 104.28L108.409 54.4068L79.5994 69.906L78.8784 91.338L60.5588 80.1495L31.7106 95.6693L74.9899 120.748L72.042 172.474L100.852 156.975L101.694 133.748L121.463 145.886L150.273 130.387L105.6 104.28Z"
                fill="#FFC63F"
              />
            </svg>
            <p className="text-center text-gray-900 font-inter text-[20px] font-semibold leading-[125%]">
              Sign In
            </p>
            <Button
              variant="bordered"
              fullWidth
              radius="sm"
              className="border-[#3A21C4] border-[1px]"
              onPress={redirectToMicrosoftAuth}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M9.52382 1.35962H0.952393V9.93105H9.52382V1.35962Z"
                  fill="#F25022"
                />
                <path
                  d="M9.52382 10.8833H0.952393V19.4547H9.52382V10.8833Z"
                  fill="#00A4EF"
                />
                <path
                  d="M19.0476 1.35962H10.4762V9.93105H19.0476V1.35962Z"
                  fill="#7FBA00"
                />
                <path
                  d="M19.0476 10.8833H10.4762V19.4547H19.0476V10.8833Z"
                  fill="#FFB900"
                />
              </svg>
              Sign in with Microsoft
            </Button>
          </div>
          <div className="py-6 flex flex-col gap-8 justify-center px-6 md:px-0">
            <div className="flex flex-col gap-2">
              <p className="flex gap-2 items-center text-gray-900 font-inter text-[20px] font-bold leading-none">
                <span>
                  <SealCheck
                    size={20}
                    className="fill-[#2C1A99] color-[#2C1A99]"
                  />
                </span>
                Assign all licenses
              </p>
              <div>
                <ul className="list-disc pl-6 self-stretch text-primary-700 font-inter text-base font-normal leading-[150%]">
                  <li>Manage Xylo licenses: assign, reassign, or remove.</li>
                  <li>Employees get automatic onboarding emails.</li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <p className="flex gap-2 items-center text-gray-900 font-inter text-[20px] font-bold leading-none">
                <span>
                  <SealCheck
                    size={20}
                    className="fill-[#2C1A99] color-[#2C1A99]"
                  />
                </span>
                Add company documents
              </p>
              <div>
                <ul className="list-disc pl-6 self-stretch text-primary-700 font-inter text-base font-normal leading-[150%]">
                  <li>Improve Xylo with company-specific documents.</li>
                  <li>
                    Upload PDFs, TXT, DOC, and DOCX files, including handbooks,
                    acronym lists, company values, etc.
                  </li>
                  <li>
                    Xylo emails will use the uploaded document information.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <p className="flex gap-2 items-center text-gray-900 font-inter text-[20px] font-bold leading-none">
                <span>
                  <Clock size={20} className="fill-[#2C1A99] color-[#2C1A99]" />
                </span>
                View Productivity{" "}
                <Chip
                  radius="sm"
                  size="sm"
                  classNames={{ base: "bg-[#EDEBFE] text-[#5521B5] ml-2 mr-2" }}
                >
                  Coming Soon!
                </Chip>
              </p>
              <div>
                <ul className="list-disc pl-6 self-stretch text-primary-700 font-inter text-base font-normal leading-[150%]">
                  <li>Track email improvements</li>
                  <li>Measure time savings with Xylo</li>
                  <li>See average Email Score boosts</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
