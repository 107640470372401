import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./page_components/header";
import Sidebar from "./page_components/sidbar";
import PageRouter from "./page_components/pagerouter";
import ErrorBoundary from "./page_components/errorBoundary";
import { LoginHome } from "./page_components/auth/login";
import { StateManagerProvider } from "./handlers/statemanager";

function App() {
  return (
    <ErrorBoundary>
      <Router basename="/admin_panel">
        <Routes>
          <Route path="/login" element={<LoginHome />} />
          <Route
            path="*"
            element={
              <StateManagerProvider>
                <MainLayout />
              </StateManagerProvider>
            }
          />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

const MainLayout = () => {
  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex h-screen sm:flex-row flex-col">
        <Sidebar />
        <PageRouter />
      </div>
    </div>
  );
};

export default App;
