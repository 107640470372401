import toast from "react-hot-toast";
import { WarningCircle, X } from "@phosphor-icons/react";

export const addSuccessNotify = () =>


  toast.custom((t) => (
    <div className={` ${      t.visible ? 'animate-enter' : 'animate-leave'} flex w-80  px-4 py-[6px] items-start rounded-md bg-[#EDF7ED]  gap-2 px-4 py-[6px] shadow-[0px_10px_15px_-3px_rgba(23,201,100,0.40),0px_4px_6px_-2px_rgba(0,0,0,0.05)]`}>
      <div className="flex h-full items-start">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M15.2074 6.94825L9.16658 12.9891L5.87575 9.70742L4.58325 10.9999L9.16658 15.5833L16.4999 8.24992L15.2074 6.94825ZM10.9999 1.83325C5.93992 1.83325 1.83325 5.93992 1.83325 10.9999C1.83325 16.0599 5.93992 20.1666 10.9999 20.1666C16.0599 20.1666 20.1666 16.0599 20.1666 10.9999C20.1666 5.93992 16.0599 1.83325 10.9999 1.83325ZM10.9999 18.3333C6.94825 18.3333 3.66659 15.0516 3.66659 10.9999C3.66659 6.94825 6.94825 3.66659 10.9999 3.66659C15.0516 3.66659 18.3333 6.94825 18.3333 10.9999C18.3333 15.0516 15.0516 18.3333 10.9999 18.3333Z"
            fill="#2E7D32"
          />
        </svg>
      </div>
      <div className="flex flex-col ">
        <div className="flex justify-between">
            <div>          <p className="text-green-900 font-inter text-base">Member Added</p></div>

        </div>

        <p className="text-green-900 font-roboto font-normal text-body2 text-sm leading-snug tracking-tighter">
          Member added without a license
        </p>
      </div>
    </div>
  ))
  

  export const errorNotify = () =>
    toast.custom(
      <div className="flex w-80 items-start rounded-md bg-[#F31260] gap-2 border-[#F31260] px-4 py-[6px] shadow-xl">
        <div className="flex h-full items-start">
          <WarningCircle size={22} className="text-white" />
        </div>
        <div className="flex flex-col ">
          <p className="text-white font-inter text-base">
            Invite Error
          </p>
          <p className="text-white font-roboto font-normal text-body2 text-sm leading-snug tracking-tighter">
            There was an error submitting your request
          </p>
        </div>
      </div>
    );

export const editSuccesNotify = () =>
  toast.custom(
    <div className="flex w-80 items-start rounded-md bg-[#EDF7ED]  gap-2 border-red-500 px-4 py-[6px] shadow-xl">
      <div className="flex h-full items-start">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M15.2074 6.94825L9.16658 12.9891L5.87575 9.70742L4.58325 10.9999L9.16658 15.5833L16.4999 8.24992L15.2074 6.94825ZM10.9999 1.83325C5.93992 1.83325 1.83325 5.93992 1.83325 10.9999C1.83325 16.0599 5.93992 20.1666 10.9999 20.1666C16.0599 20.1666 20.1666 16.0599 20.1666 10.9999C20.1666 5.93992 16.0599 1.83325 10.9999 1.83325ZM10.9999 18.3333C6.94825 18.3333 3.66659 15.0516 3.66659 10.9999C3.66659 6.94825 6.94825 3.66659 10.9999 3.66659C15.0516 3.66659 18.3333 6.94825 18.3333 10.9999C18.3333 15.0516 15.0516 18.3333 10.9999 18.3333Z"
            fill="#2E7D32"
          />
        </svg>
      </div>
      <div className="flex flex-col ">
        <p className="text-green-900 font-inter text-base">Selection saved</p>
        <p className="text-green-900 font-roboto font-normal text-body2 text-sm leading-snug tracking-tighter">
          User profile has been updated
        </p>
      </div>
    </div>
  );

export const editErrorNotify = () =>
  toast.custom(
    <div className="flex w-80 items-start rounded-md bg-[#F31260] gap-2 px-4 py-[6px] shadow-xl">
      <div className="flex h-full items-start">
        <WarningCircle size={22} className="text-white" />
      </div>
      <div className="flex flex-col ">
        <p className="text-white font-inter text-base">Update Error</p>
        <p className="text-white font-roboto font-normal text-body2 text-sm leading-snug tracking-tighter">
          There was an error submitting your request
        </p>
      </div>
    </div>
  );


  export const toggleLicenseSuccess = () => {

  toast.custom((t) => (
    <div className={` ${      t.visible ? 'animate-enter' : 'animate-leave'} flex w-80  px-4 py-[6px] items-start rounded-md bg-[#EDF7ED]  gap-2 px-4 py-[6px] shadow-[0px_10px_15px_-3px_rgba(23,201,100,0.40),0px_4px_6px_-2px_rgba(0,0,0,0.05)]`}>
      <div className="flex h-full items-start">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M15.2074 6.94825L9.16658 12.9891L5.87575 9.70742L4.58325 10.9999L9.16658 15.5833L16.4999 8.24992L15.2074 6.94825ZM10.9999 1.83325C5.93992 1.83325 1.83325 5.93992 1.83325 10.9999C1.83325 16.0599 5.93992 20.1666 10.9999 20.1666C16.0599 20.1666 20.1666 16.0599 20.1666 10.9999C20.1666 5.93992 16.0599 1.83325 10.9999 1.83325ZM10.9999 18.3333C6.94825 18.3333 3.66659 15.0516 3.66659 10.9999C3.66659 6.94825 6.94825 3.66659 10.9999 3.66659C15.0516 3.66659 18.3333 6.94825 18.3333 10.9999C18.3333 15.0516 15.0516 18.3333 10.9999 18.3333Z"
            fill="#2E7D32"
          />
        </svg>
      </div>
      <div className="flex flex-col ">
        <div className="flex justify-between">
            <div>          <p className="text-green-900 font-inter text-base">License Changed</p></div>

        </div>

        <p className="text-green-900 font-roboto font-normal text-body2 text-sm leading-snug tracking-tighter">
          Members license status successfully changed
        </p>
      </div>
    </div>
  ))
  }

  export const toggleLicenseError = () =>
    toast.custom(
      <div className="flex w-80 items-start rounded-md bg-[#F31260] gap-2 px-4 py-[6px] shadow-xl">
        <div className="flex h-full items-start">
          <WarningCircle size={22} className="text-white" />
        </div>
        <div className="flex flex-col ">
          <p className="text-white font-inter text-base">Update Error</p>
          <p className="text-white font-roboto font-normal text-body2 text-sm leading-snug tracking-tighter">
            There was an error submitting your request
          </p>
        </div>
      </div>
    );