const columns = [
  { name: "ID", uid: "id", sortable: true },
  { name: "Name", uid: "name", sortable: true },
  // { name: "First Name", uid: "user_first_name", sortable: true },
  // { name: "Last Name", uid: "user_last_name", sortable: true },
  { name: "Email", uid: "email", sortable: true },
  // { name: "Organization", uid: "organization" },
  // { name: "Team", uid: "team" },
  // { name: "Status", uid: "status" },
  { name: "Role", uid: "role" },
  { name: "Assign License", uid: "actions" },
];

// Set role based on condition



const statusOptions = [
  { name: "Active", uid: "active" },
  { name: "Paused", uid: "paused" },
  { name: "Vacation", uid: "vacation" },
];



const users = [
  {
    id: 1,
    name: "Tony Reichert",
    role: "CEO",
    team: "Management",
    status: "active",
    age: "29",
    avatar: "https://i.pravatar.cc/150?u=a042581f4e29026024d",
    email: "tony.reichert@example.com",
  },
  {
    id: 2,
    name: "Zoey Lang",
    role: "Tech Lead",
    team: "Development",
    status: "paused",
    age: "25",
    avatar: "https://i.pravatar.cc/150?u=a042581f4e29026704d",
    email: "zoey.lang@example.com",
  },


];

export { columns, users, statusOptions };
