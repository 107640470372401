import React, { useState } from "react";
import { Button, Chip } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import {
  Trophy,
  TreeStructure,
  FileArrowUp,
} from "@phosphor-icons/react";

interface SidebarProps {
  // Define props if needed
}

const Sidebar: React.FC<SidebarProps> = () => {
  const [selectedButton, setSelectedButton] = useState<string>("organization"); // State to track the selected button
  const navigate = useNavigate();

  const handleButtonClick = (buttonName: string) => {
    setSelectedButton(buttonName); // Update selected button state
    navigate(`/${buttonName}`);
  };

  return (
    <div className="sidebar bg-white flex sm:flex-col items-start sm:p-6 gap-4 lg:min-w-[20%]  p-2">
      <Button
        className={`flex h-12 px-4 items-center gap-3 self-stretch rounded-md justify-start ${
          selectedButton === "organization" ? "bg-[#ECE8FF]" : "bg-white"
        } text-base leading-6 font-medium font-inter text-[#3A21C4]`}
        onClick={() => handleButtonClick("organization")}
      >
        <TreeStructure size={24} className="shrink-0" />
        <span className="hidden sm:inline md:hidden">Organization</span>
        <span className="hidden md:inline">Organization</span>
      </Button>
      <Button
        className={`flex h-12 px-4 items-center gap-3 self-stretch rounded-md justify-start ${
          selectedButton === "productivity" ? "bg-[#ECE8FF]" : "bg-white"
        } text-base leading-6 font-medium font-inter text-[#3A21C4]`}
        // onClick={() => handleButtonClick("productivity")}
      >
        <Trophy size={24} className="shrink-0" />

        <span className="hidden sm:inline md:hidden">Productivity</span>
        <span className="hidden md:inline flex">
          Productivity{" "}
          <Chip
            radius="sm"
            size="sm"
            classNames={{ base: "bg-purple-100 text-purple-800 ml-3" }}
          >
            Soon
          </Chip>
        </span>
      </Button>

      <Button
        className={`flex h-12 px-4 items-center gap-3 self-stretch rounded-md justify-start ${
          selectedButton === "companydocuments" ? "bg-[#ECE8FF]" : "bg-white"
        } text-base leading-6 font-medium font-inter text-[#3A21C4]`}
        // onClick={() => handleButtonClick("companydocuments")}
      >
        <FileArrowUp size={24} className="shrink-0" />
        <span className="hidden sm:inline md:hidden">Company Documents</span>
        <span className="hidden md:inline">
          Company Documents{" "}
          <Chip
            radius="sm"
            size="sm"
            classNames={{ base: "bg-purple-100 text-purple-800 ml-3" }}
          >
            Soon
          </Chip>
        </span>
      </Button>
    </div>
  );
};

export default Sidebar;
