// apiUtils.tsx
import Cookies from "js-cookie";

type RequestOptions = {
  method?: string;
  headers?: Record<string, string>;
  body?: string;
};

let BASE_URL = process.env.REACT_APP_API_URL || 'https://app.dev.teamxylo.com/api';

/**
 * Fetches the access token using the refresh token stored in cookies.
 * @returns A promise that resolves to the access token.
 * @throws An error if the refresh token is not available or if the network response is not ok.
 */
const fetchAccessToken = async (): Promise<string> => {
  const refreshToken = Cookies.get('refresh_token');

  if (!refreshToken) {
    throw new Error('No refresh token available');
  }

  const options: RequestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refresh: refreshToken })
  };

  const response = await fetch(`${BASE_URL}/token/refresh/`, options);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  return data.access;
};

const authenticatedRequest = async (url: string, options: RequestOptions = {}, baseURL?: string): Promise<any> => {
  let accessToken = Cookies.get('access_token');

  if (!accessToken) {
    try {
      accessToken = await fetchAccessToken();
    } catch (error) {
      console.error("Failed to fetch access token:", error);
      throw error; // Re-throw the error to handle it appropriately in the caller
    }
  }

  const headers = {
    ...options.headers,
    Authorization: `Bearer ${accessToken}`,
  };

  const finalURL = baseURL ? `${baseURL}${url}` : `${BASE_URL}${url}`;

  const response = await fetch(finalURL, {
    ...options,
    headers,
  });

  if (!response.ok) {
    if (response.status === 401) {
      // Token might be expired, fetch a new token and retry
      try {
        accessToken = await fetchAccessToken();
        headers.Authorization = `Bearer ${accessToken}`;
        const retryResponse = await fetch(finalURL, {
          ...options,
          headers,
        });

        if (!retryResponse.ok) {
          throw new Error("Network response was not ok");
        }

        const retryData = await retryResponse.json();
        return retryData;
      } catch (error) {
        console.error("Failed to fetch access token on retry:", error);
        throw error; // Re-throw the error to handle it appropriately in the caller
      }
    } else {
      throw new Error("Network response was not ok");
    }
  }

  const data = await response.json();
  return data;
};

export { fetchAccessToken, authenticatedRequest };