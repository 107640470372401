import React from "react";
import { logoutUser } from "../handlers/auth";
import { Button } from "@nextui-org/react";
import { useStateManager } from "../handlers/statemanager";

const LogoutButton: React.FC = () => {
  const { state } = useStateManager();
  const handleLogout = async () => {
    try {
      await logoutUser();
      // send the user to the login page
      window.location.href = "/admin_panel/login";
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <>
      {state.userDetails && state.userDetails.email && (
        <div className="flex gap-2 items-center">
          <p
            className="text-white opacity-0 transition-opacity duration-1000 delay-1000 "
            style={{ opacity: 1 }}
          >
            {state.userDetails.email}
          </p>
          <Button
            variant="bordered"
            className="text-white"
            size="sm"
            onPress={handleLogout}
          >
            Logout
          </Button>
        </div>
      )}
    </>
  );
};

export default LogoutButton;
