import React, { FC, useState, useEffect } from "react";
import {
  Button,
  Input,
  Spinner,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
} from "@nextui-org/react";
import { CaretRight, XCircle, WarningCircle } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import OrgSvg from "./assets/organizationemptystate";
import UserTable from "./usertable";
import {
  createUserInvite,
  fetchUserDetails,
  updateOrgUser,
  fetchOrganizationUsers,
  fetchInviteUser,
  sendUserInvite,
  updateInviteUser,
} from "../handlers/api";
import { useStateManager } from "../handlers/statemanager";

import {
  addSuccessNotify,
  editSuccesNotify,
  editErrorNotify,
  errorNotify,
} from "./toasts/toasts";
import { stat } from "fs";

interface OrganizationProps {
  // Define props here
}

interface FormData {
  email: string;
  team: string;
  firstName: string;
  lastName: string;
  entity: string;
  permissionLevel: number | string;
}

const Organization: FC<OrganizationProps> = () => {
  const { onOpenChange } = useDisclosure();
  const [canSubmit, setCanSubmit] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [editEmail, setEditEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [permissionLevel, setPermissionLevel] = useState(2);
  const { state } = useStateManager();

  const [formData, setFormData] = useState<FormData>({
    email: "",
    team: "",
    firstName: "",
    lastName: "",
    entity: "",
    permissionLevel: "",
  });

  const handleEditModelOpen = () => {
    state.setEditUserModelOpen(!state.editUserModelOpen);
  };

  useEffect(() => {
    const { team, email, permissionLevel } = formData;
    const isFormDataEmpty = email === "" || permissionLevel === "";
    setCanSubmit(!isFormDataEmpty);
  }, [formData]);

  const [formErrors, setFormErrors] = useState<FormData>({
    email: "",
    team: "",
    firstName: "",
    lastName: "",
    entity: "",
    permissionLevel: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    let permissionLevelValue: number | string;

    if (value === "1") {
      permissionLevelValue = 1;
    } else if (value === "2") {
      permissionLevelValue = 2;
    } else {
      permissionLevelValue = value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: permissionLevelValue,
    }));

    //note not all of these being updated are permission level this was just a cleaner way for now will modify

    
    
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent the default form submission behavior
    if (validateForm()) {
      submitForm();
    }
  };

  const handleModelOpen = () => {
    state.setModelOpen(!state.modelOpen);

    setFormData({
      email: "",
      team: "",
      firstName: "",
      lastName: "",
      entity: "",
      permissionLevel: "",
    });
  };

  useEffect(() => {
    async function fetchUserDetailsForEdit(user: any) {
      setIsLoading(true);

      let data;

      if (user?.user) {
        data = await fetchUserDetails(user.user);
      } else {
        data = await fetchInviteUser(state.organizationId, user.id);
      }

      
      setEditEmail(data.email);

      const temp = {
        email: data.email,
        team: state.editUserDetails.team ? state.editUserDetails.team : "",
        firstName: data.first_name,
        lastName: data.last_name,
        entity: state.organization?.name || "",
        permissionLevel: data?.organizations?.[0]?.role || data?.role,
      };

      
      

      setFormData(temp);
      

      
      setIsLoading(false);
      setPermissionLevel(data?.organizations?.[0]?.role || data?.role);
      
      
    }

    if (state.editUserDetails) {
      fetchUserDetailsForEdit(state.editUserDetails);
    }
  }, [state.editUserDetails, state.organization?.name]);

  useEffect(() => {
    
  }, [formData]);

  const validateForm = () => {
    const { email, permissionLevel } = formData;
    const errors = {
      email: "",
      team: "",
      firstName: "",
      lastName: "",
      entity: "",
      permissionLevel: "",
    };
    let isValid = true;
    // Perform validation on form fields here
    if (email.trim() === "") {
      errors.email = "Email is required";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        errors.email = "Invalid email format";
      }
    }


    if (!permissionLevel) {
      errors.permissionLevel = "Permission level is required";
    }

    setFormErrors(errors);

    Object.values(errors).forEach((error) => {
      if (error !== "") {
        isValid = false;
      }
    });

    return isValid;
  };

  const submitForm = () => {
    setSubmissionLoading(true);
    async function call() {
      if (state.modelOpen) {
        
        const newUserEmail = formData.email;

        const data = await createUserInvite(
          state.organizationId,
          formData.email,
          formData.permissionLevel,
          formData.team
        );
        
        setSubmissionLoading(false);
        if (data) {
          handleModelOpen();
          addSuccessNotify();
          state.refreshOrganizationUsers(newUserEmail);

        } else {
          
          errorNotify();
          return;
        }
      }
      if (state.editUserModelOpen) {
        

        let data;
        if (state.editUserDetails?.user) {
          data = await updateOrgUser(
            state.editUserDetails.organization,
            state.editUserDetails.id,
            formData.email,
            formData.permissionLevel,
            formData.team
          );
        } else {
          data = await updateInviteUser(
            state.organizationId,
            state.editUserDetails.id,
            formData.permissionLevel,
            formData.email
          );
        }

        

        
        setSubmissionLoading(false);
        if (data) {
          state.setEditUserModelOpen(!state.editUserModelOpen);
          setIsLoading(true);
          const call = await fetchOrganizationUsers(state.organizationId);
          state.setUsers(call);
          setIsLoading(false);
          editSuccesNotify();
        } else {
          
          editErrorNotify();
          return;
        }
      }
    }

    call();
    
    // Handle form submission here
  };

  const [value, setValue] = useState<string>("");

  const handleSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      team: e.target.value,
    }));
    
    
  };

  useEffect(() => {
    if (state.editUserDetails) {
      
    }
  }, [state.editUserDetails]);

  return (
    <>
      {!state.users || !state.invitedUsers ? (
        <div className="flex grow justify-center items-center">
          <Spinner size="lg" color="secondary" />
        </div>
      ) : (
        <>
          {state.users && state.invitedUsers ? (
            <div className="h-full w-full flex flex-col items-center justify-center">
              <div className="flex  w-full h-full flex-col gap-6 relative">
                {/* <div className="absolute z-[10] right-0 bottom-0"></div> */}
                <div className="flex justify-between items-center">
                  <div>
                    {state.licenses && (
                      <p className="text-[#18181B] text-sm font-inter font-normal leading-[20px] pb-[10px]">
                        Available licenses {state.licenses.taken_licenses} /{" "}
                        {state.licenses.total_licenses}
                      </p>
                    )}
                    <div className="flex gap-4">
                      <Button
                        onPress={handleModelOpen}
                        className="flex sm:w-[226px] h-[48px] px-4 justify-center items-center gap-3 rounded-lg bg-[#3A21C4] text-inter text-base font-medium leading-6 text-white"
                      >
                        Add Member
                      </Button>
                      <Button
                        onPress={() =>
                          window.open("https://xylo.ai/pricing", "_blank")
                        }
                        className="flex sm:w-[226px] h-[48px] px-4 justify-center items-center gap-3 rounded-lg bg-white text-inter text-base font-medium leading-6 text-[#3A21C4] border-2 border-[#3A21C4]"
                      >
                        Get Licenses
                      </Button>
                    </div>
                  </div>
                  <Modal
                    backdrop="blur"
                    isOpen={state.modelOpen}
                    onOpenChange={onOpenChange}
                    onClose={handleModelOpen}
                    // closeButton={<Button onPress={handleModelOpen}>close</Button>}
                    placement="top-center"
                    size="2xl"
                    className="min-h-[30%]"
                  >
                    <ModalContent>
                      {(_) => (
                        <>
                          <ModalHeader className="flex p-4 justify-between items-center bg-purple-100 text-base leading-6 font-bold font-inter text-black self-stretch">
                            Employee Invitation
                          </ModalHeader>

                          <ModalBody className="pt-8 px-4">
                            <form
                              action="submit"
                              className="flex flex-col gap-4 "
                            >
                              <div className="flex align-self-stretch gap-6">
                                <Input
                                  autoFocus
                                  isRequired
                                  label="Email"
                                  placeholder="dillon@teamxylo.com"
                                  variant="bordered"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleChange}
                                  isInvalid={!!formErrors.email}
                                  errorMessage={formErrors.email}
                                />
                              </div>

                              <div className="pt-8">
                                <RadioGroup
                                  isRequired
                                  label="Permission level"
                                  orientation="horizontal"
                                  name="permissionLevel"
                                  value={formData.permissionLevel.toString()}
                                  onChange={handleChange}
                                  isInvalid={!!formErrors.permissionLevel}
                                  errorMessage={formErrors.permissionLevel}
                                  classNames={{
                                    wrapper: "flex gap-4",
                                  }}
                                >
                                  <Radio value="1">Admin</Radio>
                                  <Radio value="2">Employee</Radio>
                                  {/* <Radio value="no-access">No Access</Radio> */}
                                </RadioGroup>
                              </div>
                              <p className="text-xs text-[#52525B]">
                                After adding an organization member assign their
                                license via the toggle in the assign license
                                column.
                              </p>
                            </form>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg border-2 border-gray-300 text-base leading-6  font-inter text-black"
                              variant="flat"
                              onPress={handleModelOpen}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg bg-gray-100 text-base leading-6  font-inter text-gray-700"
                              onClick={handleSubmit}
                              isDisabled={!canSubmit}
                              isLoading={submissionLoading}
                            >
                              Add Org Member
                            </Button>
                          </ModalFooter>
                        </>
                      )}
                    </ModalContent>
                    <Toaster position="top-right">
                      {(t) => (
                        <ToastBar toast={t} style={{ background: "#F31260" }}>
                          {({ icon, message }) => (
                            <div>
                              {icon}
                              {message}
                              {t.type !== "loading" && (
                                <button
                                  onClick={() => toast.dismiss(t.id)}
                                  className="absolute top-0 right-0 p-4"
                                >
                                  <XCircle className="text-white" />
                                </button>
                              )}
                            </div>
                          )}
                        </ToastBar>
                      )}
                    </Toaster>
                  </Modal>
                </div>
                <div
                  className="w-full max-h-[fit-content] bg-white rounded-lg pt-3 px-4 flex flex-col gap-2 pb-2"
                  style={{
                    boxShadow:
                      "0px 0px 1px 0px rgba(0, 0, 0, 0.30), 0px 2px 10px 0px rgba(0, 0, 0, 0.06), 0px 0px 5px 0px rgba(0, 0, 0, 0.02)",
                  }}
                >
                  {state.users && state.invitedUsers ? (
                    <UserTable />
                  ) : (
                    <div className="flex grow justify-center items-center">
                      <Spinner size="lg" color="secondary" />
                    </div>
                  )}
                </div>
              </div>
              <Toaster position="top-right">
                {(t) => (
                  <ToastBar toast={t} style={{ background: "#EDF7ED" }}>
                    {({ icon, message }) => (
                      <div>
                        {icon}
                        {message}
                        {t.type !== "loading" && (
                          <button
                            onClick={() => toast.dismiss(t.id)}
                            className="absolute top-0 right-0 p-4"
                          >
                            <XCircle />
                          </button>
                        )}
                      </div>
                    )}
                  </ToastBar>
                )}
              </Toaster>
            </div>
          ) : (
            <div className="h-full flex w-full items-center justify-center">
              <div className="flex flex-col justify-center items-center gap-6 self-stretch">
                <OrgSvg />
                <div className="flex flex-col gap-4">
                  <p className="text-center text-inter text-xl font-semibold leading-7 text-[#52525B] self-stretch">
                    Here you can add Employees or <br></br> Admin to start use
                    Harmony
                  </p>
                  <p className="text-center text-inter text-sm font-normal leading-5 self-stretch text-[#52525B]">
                    You have no Employees or Admin added yet
                  </p>
                </div>

                <div className="flex gap-4">
                  <Button
                    onPress={handleModelOpen}
                    className="flex w-[226px] h-[48px] px-4 justify-center items-center gap-3 rounded-lg bg-[#3A21C4] text-inter text-base font-medium leading-6 text-white"
                  >
                    Add Member
                  </Button>
                  <Button
                    onPress={handleModelOpen}
                    className="flex w-[226px] h-[48px] px-4 justify-center items-center gap-3 rounded-lg bg-white text-inter text-base font-medium leading-6 text-[#3A21C4] border-2 border-[#3A21C4]"
                  >
                    Get Licenses
                  </Button>
                </div>
                <Modal
                  backdrop="blur"
                  isOpen={state.modelOpen}
                  onOpenChange={onOpenChange}
                  onClose={handleModelOpen}
                  // closeButton={<Button onPress={handleModelOpen}>close</Button>}
                  placement="top-center"
                  size="2xl"
                  className="min-h-[30%]"
                >
                  <ModalContent>
                    {(_) => (
                      <>
                        <ModalHeader className="flex p-4 justify-between items-center bg-purple-100 text-base leading-6 font-bold font-inter text-black self-stretch">
                          Employee Invitation
                        </ModalHeader>

                        <ModalBody className="pt-8 px-4">
                          <form
                            action="submit"
                            className="flex flex-col gap-4 "
                          >
                            <div className="flex align-self-stretch gap-6">
                              <Input
                                autoFocus
                                isRequired
                                label="Email"
                                placeholder="dillon@teamxylo.com"
                                variant="bordered"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                isInvalid={!!formErrors.email}
                                errorMessage={formErrors.email}
                              />
                              <Input
                                label="Team"
                                isRequired
                                placeholder="Eg: Sales"
                                variant="bordered"
                                name="team"
                                value={formData.team}
                                onChange={handleChange}
                                isInvalid={!!formErrors.team}
                                errorMessage={formErrors.team}
                              />
                            </div>

                            <div className="flex align-self-stretch gap-6">
                              <Input
                                label="First Name"
                                isRequired
                                placeholder="Insert your First Name"
                                variant="bordered"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                isInvalid={!!formErrors.firstName}
                                errorMessage={formErrors.firstName}
                              />
                              <Input
                                label="Last Name (Optional)"
                                placeholder="Insert your Last Name"
                                variant="bordered"
                                isRequired
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                isInvalid={!!formErrors.lastName}
                                errorMessage={formErrors.lastName}
                              />
                              <Input
                                label="Entity (Company Name)"
                                placeholder="Eg: Google"
                                variant="bordered"
                                name="entity"
                                value={state.organization?.name || ""}
                                isDisabled
                                onChange={handleChange}
                                isInvalid={!!formErrors.entity}
                                errorMessage={formErrors.entity}
                              />
                            </div>

                            <div className="pt-8">
                              <RadioGroup
                                isRequired
                                label="Permission level"
                                orientation="horizontal"
                                name="permissionLevel"
                                value={
                                  formData.permissionLevel.toString() === "1"
                                    ? "admin"
                                    : "employee"
                                }
                                onChange={handleChange}
                                isInvalid={!!formErrors.permissionLevel}
                                errorMessage={formErrors.permissionLevel}
                                classNames={{
                                  wrapper: "flex gap-4",
                                }}
                              >
                                <Radio
                                  value="admin"
                                  defaultChecked={
                                    permissionLevel.toString() === "1"
                                  }
                                >
                                  Admin
                                </Radio>
                                <Radio
                                  value="employee"
                                  defaultChecked={
                                    permissionLevel.toString() === "2"
                                  }
                                >
                                  Employee
                                </Radio>
                                {/* <Radio value="no-access">No Access</Radio> */}
                              </RadioGroup>
                            </div>
                          </form>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg border-2 border-gray-300 text-base leading-6  font-inter text-black"
                            variant="flat"
                            onPress={handleModelOpen}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg bg-gray-100 text-base leading-6  font-inter text-gray-700"
                            onClick={handleSubmit}
                            isDisabled={!canSubmit}
                            isLoading={submissionLoading}
                          >
                            Assign License
                          </Button>
                        </ModalFooter>
                      </>
                    )}
                  </ModalContent>
                </Modal>
              </div>
            </div>
          )}

          {state.editUserModelOpen ? (
            <Modal
              backdrop="blur"
              isOpen={state.editUserModelOpen}
              onClose={handleEditModelOpen}
              onOpenChange={onOpenChange}
              // onClose={state.setEditUserModelOpen(false)}
              // closeButton={<Button onPress={handleModelOpen}>close</Button>}
              placement="top-center"
              size="2xl"
              className="min-h-[30%]"
            >
              <ModalContent>
                {(_) => (
                  <>
                    <ModalHeader className="flex p-4 justify-between items-center bg-purple-100 text-base leading-6 font-bold font-inter text-black self-stretch">
                      Edit Employee Information
                    </ModalHeader>

                    <ModalBody className="pt-8 px-4">
                      {isLoading ? (
                        <div className="flex grow justify-center items-center">
                          <Spinner size="lg" color="secondary" />
                        </div>
                      ) : (
                        <form action="submit" className="flex flex-col gap-4 ">
                          <div className="flex align-self-stretch gap-6">
                            <Input
                              autoFocus
                              label="Email"
                              placeholder="dillon@teamxylo.com"
                              variant="bordered"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              isInvalid={!!formErrors.email}
                              errorMessage={formErrors.email}
                            />
                          </div>

                          <div className="pt-8 ">
                            <RadioGroup
                              isRequired
                              label="Permission level"
                              orientation="horizontal"
                              name="permissionLevel"
                              // value={formData.permissionLevel.toString() === "1" ? "admin" : "employee"}
                              onChange={handleChange}
                              classNames={{
                                wrapper: "flex gap-4",
                              }}
                              defaultValue={formData.permissionLevel.toString()}
                            >
                              <Radio value="1">Admin</Radio>
                              <Radio value="2">Employee</Radio>
                            </RadioGroup>
                          </div>
                        </form>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg border-2 border-gray-300 text-base leading-6  font-inter text-black"
                        variant="flat"
                        onPress={handleEditModelOpen}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg bg-gray-100 text-base leading-6  font-inter text-gray-700"
                        onClick={handleSubmit}
                        isDisabled={!canSubmit}
                        isLoading={submissionLoading}
                      >
                        Save
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
          ) : null}
        </>
      )}
    </>
  );
};

export default Organization;
