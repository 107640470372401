import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem
} from "@nextui-org/react";
import { useStateManager } from "../handlers/statemanager";

import React from "react";
import LogoutButton from "./logoutbutton";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { state } = useStateManager();

  return (
    <Navbar className="bg-[#110A44] sm:flex-col flex-row" maxWidth="full" >
      <NavbarBrand className="flex gap-2 items-center">
        <div className="flex items-center justify-center">
          {state.organization?.name && (
            <p
              className="text-white text-lg font-medium font-family-inter leading-7"
              style={{ opacity: 1 }}
            >
              {state.organization.name}
            </p>
          )}
        </div>
      </NavbarBrand>

      <NavbarContent justify="end">
        <NavbarItem className="flex items-center">
            <LogoutButton />
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
};

export default Header;
